<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <div style="font-size:12px; background-color: #337982; padding:6px">
                                  <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                                </div>
                                <div class="table-responsive">
                                  <b-table-simple striped bordered small class="mt-2">
                                      <b-tr>
                                          <b-th>{{ $t('dae_grant_allocation_distribution.fiscal_year') }}</b-th>
                                          <b-td class="text-capitalize"> {{ currentLocale === 'en' ? form.fiscal_year : form.fiscal_year_bn }} </b-td>
                                          <b-th>{{ $t('dae_grant_allocation_distribution.season') }}</b-th>
                                          <b-td class="text-capitalize">{{ currentLocale === 'en' ? form.seasson : form.season_bn }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th>{{ $t('dae_subsidy.circular_type') }}</b-th>
                                          <b-td class="text-capitalize" colspan="3">{{ currentLocale === 'en' ? form.circular_type_en : form.circular_type_bn }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th>{{ $t('dae_config.circular_name') }}</b-th>
                                          <b-td class="text-capitalize"> {{ currentLocale === 'en' ? form.circular_name : form.circular_name_bn }} </b-td>
                                          <b-th>{{ $t('dae_config.crop_name') }}</b-th>
                                          <b-td class="text-capitalize"> {{ currentLocale === 'en' ? form.crop_name : form.crop_name_bn }} </b-td>
                                      </b-tr>
                                  </b-table-simple>
                                </div>
                                <div class="other-circular-area" v-if="form.circular_type !== 4">
                                  <div style="font-size:12px; background-color: #337982; padding:6px">
                                    <h5 class="text-white text-center"> {{ $t('dae_grant_allocation_distribution.allocation_info') }}</h5>
                                  </div>
                                  <div class="table-responsive">
                                  <b-table-simple striped bordered small class="mt-3" v-if="form.district_id !== 0">
                                      <b-thead>
                                          <b-tr>
                                              <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                                              <b-th rowspan="4" class="text-center align-top">{{ $t('org_pro_district.district') }}</b-th>
                                              <b-th :colspan="4+(materials.length*2)" class="text-center">{{ $t('rehabilitaionAllocation.crop_fertilizer') }}</b-th>
                                              <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.cash_tk') }}</b-th>
                                              <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.transport_cost') }}</b-th>
                                              <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.other_cost') }}</b-th>
                                              <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.totalAmount') }}{{ $t('rehabilitaionAllocation.tk') }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                              <b-th colspan="2"></b-th>
                                              <b-th colspan="2" class="text-center text-capitalize">{{ currentLocale === 'en' ? form.crop_name : form.crop_name_bn }}</b-th>
                                              <b-th :colspan="materials.length * 2" class="text-center">{{ $t('rehabilitaionAllocation.fertilizer') }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                              <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.no_of_farmer') }}</b-th>
                                              <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.land_qty') }}</b-th>
                                              <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.qty') }}</b-th>
                                              <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.amount') }}</b-th>
                                              <b-th colspan="2" class="text-center text-capitalize" v-for="(material, materialIndex) in materials" :key="materialIndex">{{ currentLocale === 'en' ? material.material_name : material.material_name_bn }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                          <template v-for="(matirial, matirialIndex) in materials">
                                              <b-th class="text-center" :key="matirialIndex+200">{{ $t('rehabilitaionAllocation.qty') }}</b-th>
                                              <b-th class="text-center" :key="matirialIndex+400">{{ $t('rehabilitaionAllocation.amount') }}</b-th>
                                          </template>
                                          </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                          <tr>
                                          <td>{{ $n(1) }}</td>
                                          <td class="text-capitalize">{{ currentLocale === 'bn' ? districtAllocation.district_name_bn : districtAllocation.district_name }}</td>
                                          <td class="text-center">
                                              {{ $n(districtAllocation.total_farmer, { useGrouping: false }) }}
                                          </td>
                                          <td class="text-center">
                                              {{ $n(districtAllocation.land_quantity, { useGrouping: false }) }}
                                          </td>
                                          <td class="text-center">
                                              {{ $n(districtAllocation.crop_quantity, { useGrouping: false }) }}
                                          </td>
                                          <td class="text-right">
                                              {{ $n(districtAllocation.crop_amount, { useGrouping: false }) }}
                                          </td>
                                          <template v-for="(item, index1) in districtAllocation.materials">
                                              <td :key="1+100+index1+100" class="text-center">{{ $n(item.quantity, { useGrouping: false }) }}</td>
                                              <td :key="1+200+index1+200" class="text-right">{{ $n(item.amount, { useGrouping: false }) }}</td>
                                          </template>
                                          <td class="text-right">{{ $n(districtAllocation.cash_tk, { useGrouping: false }) }}</td>
                                          <td class="text-right">{{ $n(districtAllocation.transport_cost, { useGrouping: false }) }}</td>
                                          <td class="text-right">{{ $n(districtAllocation.other_cost, { useGrouping: false }) }}</td>
                                          <td class="text-right">{{ $n(districtAllocation.total_amount, { useGrouping: false }) }}</td>
                                          </tr>
                                      </b-tbody>
                                  </b-table-simple>
                                </div>
                                <div class="table-responsive">
                                  <b-table-simple striped bordered small class="mt-3">
                                      <b-thead>
                                          <b-tr>
                                          <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                                          <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.upazila') }}</b-th>
                                          <b-th :colspan="4+(materials.length*2)" class="text-center">{{ $t('rehabilitaionAllocation.crop_fertilizer') }}</b-th>
                                          <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.cash_tk') }}</b-th>
                                          <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.transport_cost') }}</b-th>
                                          <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.other_cost') }}</b-th>
                                          <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.totalAmount') }}{{ $t('rehabilitaionAllocation.tk') }}</b-th>
                                          </b-tr>
                                          <b-tr><b-th colspan="2"></b-th>
                                          <b-th colspan="2" class="text-center text-capitalize">{{ currentLocale === 'en' ? form.crop_name : form.crop_name_bn }}</b-th>
                                          <b-th :colspan="materials.length * 2" class="text-center">{{ $t('rehabilitaionAllocation.fertilizer') }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                          <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.no_of_farmer') }}</b-th>
                                          <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.land_qty') }}</b-th>
                                          <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.qty') }}</b-th>
                                          <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.amount') }}</b-th>
                                          <b-th colspan="2" class="text-center text-capitalize" v-for="(material, materialIndex) in materials" :key="materialIndex">{{ currentLocale === 'en' ? material.material_name : material.material_name_bn }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                              <template v-for="(matirial, matirialIndex) in materials">
                                                  <b-th class="text-center" :key="matirialIndex+200">{{ $t('rehabilitaionAllocation.qty') }}</b-th>
                                                  <b-th class="text-center" :key="matirialIndex+400">{{ $t('rehabilitaionAllocation.amount') }}</b-th>
                                              </template>
                                          </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                          <tr v-for="(detail, index) in form.details" :key="index">
                                              <td>{{ $n(index + 1) }}</td>
                                              <td class="text-capitalize">{{ currentLocale === 'bn' ? detail.upazilla_name_bn : detail.upazilla_name }}</td>
                                              <td class="text-center">{{ $n(detail.total_farmer, { useGrouping: false }) }}</td>
                                              <td class="text-center">{{ $n(detail.land_quantity, { useGrouping: false }) }}</td>
                                              <td class="text-center">{{ $n(detail.crop_quantity, { useGrouping: false }) }}</td>
                                              <td class="text-right">{{ $n(detail.crop_amount, { useGrouping: false }) }}</td>
                                              <template v-for="(item, index1) in detail.materials">
                                                  <td class="text-center" :key="index+100+index1+100">{{ $n(form.details[index].materials[index1].quantity) }}</td>
                                                  <td class="text-right" :key="index+200+index1+200">{{ $n(form.details[index].materials[index1].amount) }}</td>
                                              </template>
                                              <td class="text-right">{{ $n(detail.cash_tk, { useGrouping: false }) }}</td>
                                              <td class="text-right">{{ $n(detail.transport_cost, { useGrouping: false }) }}</td>
                                              <td class="text-right">{{ $n(detail.other_cost, { useGrouping: false }) }}</td>
                                              <td class="text-right">{{ $n(detail.total_amount, { useGrouping: false }) }}</td>
                                          </tr>
                                      </b-tbody>
                                    <b-tfoot>
                                      <b-tr>
                                        <b-th colspan="2" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                        <b-th class="text-center">
                                          {{ $n(totalItem.total_farmer) }}
                                        </b-th>
                                        <b-th class="text-center">
                                          {{  $n(totalItem.land_quantity) }}
                                        </b-th>
                                        <b-th class="text-center">
                                          {{ $n(totalItem.crop_quantity) }}
                                        </b-th>
                                        <b-th class="text-right">
                                          {{ $n(totalItem.crop_amount) }}
                                        </b-th>
                                        <template v-for="(item, materialIndex) in totalItem.materials">
                                          <b-th :key="5000+materialIndex+100" class="text-right">
                                            {{ $n(item.quantity) }}
                                          </b-th>
                                          <b-th :key="2000+materialIndex+200" class="text-right">
                                            {{ $n(item.amount) }}
                                          </b-th>
                                        </template>
                                        <b-th class="text-right">
                                          {{ $n(totalItem.cash_tk) }}
                                        </b-th>
                                        <b-th class="text-right">
                                          {{ $n(totalItem.transport_cost) }}
                                        </b-th>
                                        <b-th class="text-right">
                                          {{ $n(totalItem.other_cost) }}
                                        </b-th>
                                        <b-th class="text-right">
                                          {{ $n(totalItem.total_amount) }}
                                        </b-th>
                                      </b-tr>
                                    </b-tfoot>
                                  </b-table-simple>
                                </div>
                              </div>
                              <div v-else>
                                <div style="font-size:12px; background-color: #337982; padding:6px">
                                  <h5 class="text-white text-center"> {{ $t('dae_grant_allocation_distribution.allocation_info') }}</h5>
                                </div>
                                <div class="table-responsive">
                                  <b-table-simple striped bordered small class="mt-3">
                                    <b-thead>
                                      <b-tr>
                                        <b-th class="text-center align-top">{{ $t('org_pro_district.district') }}</b-th>
                                        <b-th class="text-center">{{ $t('rehabilitaionAllocation.no_of_farmer') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.amount') }} {{ $t('globalTrans.tk') }}</b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                      <tr>
                                        <td class="text-capitalize text-center">{{ currentLocale === 'bn' ? districtAllocation.district_name_bn : districtAllocation.district_name }}</td>
                                        <td class="text-right">
                                          {{ $n(districtAllocation.total_farmer, { useGrouping: false }) }}
                                        </td>
                                        <td class="text-right">
                                          {{ $n(districtAllocation.total_amount, { useGrouping: false }) }}
                                        </td>
                                      </tr>
                                    </b-tbody>
                                  </b-table-simple>
                                </div>
                                <div class="table-responsive">
                                  <b-table-simple striped bordered small class="mt-3">
                                    <b-thead>
                                      <b-tr>
                                        <b-th class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th class="text-center align-top">{{ $t('globalTrans.upazila') }}</b-th>
                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.no_of_farmer') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.amount') }} {{ $t('globalTrans.tk') }}</b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                      <tr v-for="(detail, index) in form.details" :key="index">
                                        <td class="text-center">{{ $n(index + 1) }}</td>
                                        <td class="text-capitalize text-center">{{ currentLocale === 'bn' ? detail.upazilla_name_bn : detail.upazilla_name }}</td>
                                        <td class="text-right">
                                          {{ $n(detail.total_farmer, { useGrouping: false }) }}
                                        </td>
                                        <td class="text-right">
                                          {{ $n(detail.total_amount, { useGrouping: false }) }}
                                        </td>
                                      </tr>
                                    </b-tbody>
                                    <b-tfoot>
                                      <b-tr>
                                        <b-th colspan="2" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                        <b-th class="text-right">
                                          {{ $n(totalItem.total_farmer, { useGrouping: false }) }}
                                        </b-th>
                                        <b-th class="text-right">
                                          {{ $n(totalItem.total_amount, { useGrouping: false }) }}
                                        </b-th>
                                      </b-tr>
                                    </b-tfoot>
                                  </b-table-simple>
                                </div>
                              </div>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { upazillaAlloShow, circularCropMaterials, districtAllocationData, distAlloPolicyInfo } from '../api/routes'

export default {
    props: ['item'],
    data () {
        return {
            id: this.item.id,
            loading: false,
            materials: [],
            districtAllocation: {},
            form: {},
            totalAmountColumnText: '',
            distributionPolicyCropInfo: {},
            distributionPolicyMatInfo: [],
            showData: false,
            totalItem: {
              total_farmer: '',
              land_quantity: '',
              crop_quantity: '',
              crop_amount: '',
              material_quantity: '',
              material_amount: '',
              cash_tk: '',
              transport_cost: '',
              other_cost: '',
              total_amount: '',
              materials: []
            }
        }
    },
    created () {
        // this.form = this.item
        // this.getDistributionPolicyInfo()
        // this.searchData()
        if (this.item.id) {
            this.getFormData(this.item.id)
        }
    },
    computed: {
        currentLocale: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        async getFormData (id) {
            this.loading = true
            const result = await RestApi.getData(incentiveGrantServiceBaseUrl, `${upazillaAlloShow}/${id}`)
            if (result.success) {
                this.form = this.item
                this.form.details = result.data.details
                this.getCircularList()
                if (this.form.circular_type !== 4) {
                  this.getCropAndMaterials(result.data.circular_id)
                } else {
                  this.searchData()
                }
            } else {
                this.form.details = []
                this.loading = false
            }
        },
        async searchData () {
            this.loading = true
            this.districtWiseUpazilla(this.form.district_id)
            const params = {}
            Object.keys(this.form).map(key => {
                if (key === 'fiscal_year_id' || key === 'season_id' || key === 'circular_id' || key === 'crop_id' || key === 'district_id') {
                    params[key] = this.form[key]
                }
            })
            await RestApi.getData(incentiveGrantServiceBaseUrl, districtAllocationData, params)
            .then(response => {
                if (response.success) {
                  this.districtAllocation = this.relationaldata(response.data)
                  this.loading = false
                } else {
                    this.districtAllocation = {}
                    this.loading = false
                }
            })
        },
        relationaldata (data) {
            const districtData = this.$store.state.commonObj.districtList.find(item => item.status === 0 && item.value === data.district_id)
            const tmpDetails = {
                district_name: districtData.text_en,
                district_name_bn: districtData.text_bn
            }
            return Object.assign(data, tmpDetails)
        },
        districtWiseUpazilla (districtId) {
            const upazillaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === districtId)
            const temp = []
            upazillaList.forEach(item => {
            const upazilaItem = this.form.details.find(detailsItem => detailsItem.upazilla_id === item.value)
            const materialArr = []
            this.materials.forEach(materialItem => {
                const upazilaMaterialItem = upazilaItem?.materials.find(materialObj => materialObj.cir_mat_id === materialItem.id)
                materialArr.push({
                cir_mat_id: materialItem.id,
                quantity: typeof upazilaMaterialItem !== 'undefined' ? upazilaMaterialItem.quantity : '',
                amount: typeof upazilaMaterialItem !== 'undefined' ? upazilaMaterialItem.amount : ''
                })
            })
            const tmpDetails = {
                upazilla_name: item.text_en,
                upazilla_name_bn: item.text_bn,
                upazilla_id: item.value,
                total_farmer: typeof upazilaItem !== 'undefined' ? upazilaItem.total_farmer : '',
                land_quantity: typeof upazilaItem !== 'undefined' ? upazilaItem.land_quantity : '',
                crop_quantity: typeof upazilaItem !== 'undefined' ? upazilaItem.crop_quantity : '',
                crop_amount: typeof upazilaItem !== 'undefined' ? upazilaItem.crop_amount : '',
                material_quantity: typeof upazilaItem !== 'undefined' ? upazilaItem.material_quantity : '',
                material_amount: typeof upazilaItem !== 'undefined' ? upazilaItem.material_amount : '',
                cash_tk: typeof upazilaItem !== 'undefined' ? upazilaItem.cash_tk : '',
                transport_cost: typeof upazilaItem !== 'undefined' ? upazilaItem.transport_cost : '',
                other_cost: typeof upazilaItem !== 'undefined' ? upazilaItem.other_cost : '',
                total_amount: typeof upazilaItem !== 'undefined' ? upazilaItem.total_amount : '',
                materials: materialArr
            }
            temp.push(tmpDetails)
            })
            this.form.details = temp
            if (this.id) {
              if (this.form.circular_type !== 4) {
                this.getDistributionPolicyInfo()
              }
              this.getTotalRowInfo()
            }
            if (this.form.circular_type !== 4) {
              this.getTotalAmountColumnText()
            }
        },
        async getCropAndMaterials (circularId) {
            this.loading = true
            await RestApi.getData(incentiveGrantServiceBaseUrl, `${circularCropMaterials}/${circularId}`)
            .then(response => {
              if (response.success) {
                  this.crops = this.getCropData(response.data.crop_details)
                  this.materials = this.getMaterialData(response.data.material_details)
                  this.totalItem.materials = []
                  this.materials.forEach(materialItem => {
                    this.totalItem.materials.push({
                      cir_mat_id: materialItem.id,
                      quantity: '',
                      amount: ''
                    })
                  })
                  if (this.item.id) {
                    this.searchData()
                  } else {
                    this.loading = false
                  }
              } else {
                  this.crops = []
                  this.materials = []
                  this.loading = false
              }
            })
        },
        async getDistributionPolicyInfo () {
            this.loading = true
            const materialIds = []
            this.materials.forEach(item => {
                materialIds.push(item.material_id)
            })
            const data = {
                fiscal_year_id: this.form.fiscal_year_id,
                season_id: this.form.season_id,
                circular_id: this.form.circular_id,
                crop_id: this.form.crop_id,
                material_ids: materialIds
            }
            const result = await RestApi.postData(incentiveGrantServiceBaseUrl, distAlloPolicyInfo, data)
            if (result.success) {
                this.distributionPolicyCropInfo = result.data.crop_details.find(item => item.crop_id === parseInt(this.form.crop_id))
                this.distributionPolicyMatInfo = result.data.mat_details
            } else {
                this.distributionPolicyCropInfo = {}
                this.distributionPolicyMatInfo = []
            }
            this.loading = false
        },
        getCropData (data) {
            return data.map(item => {
                const crop = this.$store.state.incentiveGrant.commonObj.cropList.find(crp => crp.value === item.crop_id)
                return Object.assign(item, { value: crop.value, text: (this.currentLocale === 'bn') ? crop.text_bn : crop.text_en, amount: 0 })
            })
        },
        getMaterialData (data) {
            return data.map(item => {
                const material = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(mt => mt.value === item.material_id)
                return Object.assign(item, { cir_mat_id: item.material_id, material_name: material.text_en, material_name_bn: material.text_bn, amount: 0 })
            })
        },
        getCircularList () {
            let circularList = []
            if (this.form.fiscal_year_id && this.form.season_id) {
                circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.fiscal_year_id === parseInt(this.form.fiscal_year_id) && item.season_id === parseInt(this.form.season_id) && item.circular_type === 3)
            } else {
                circularList = []
            }
            this.circularList = circularList
        },
        getTotalAmountColumnText () {
            let text = this.$n(4)
            this.materials.forEach((item, itemIndex) => {
            text += '+' + this.$n((((itemIndex + 1) * 2) + 4))
            })
            text += '+' + this.$n((parseInt((this.materials.length * 2)) + 5)) + '+' + this.$n((parseInt((this.materials.length * 2)) + 6))
            this.totalAmountColumnText = '(' + text + ')'
        },
        getTotalRowInfo () {
          let totalFarmer = 0
          let totalLandQty = 0
          let totalCropQty = 0
          let totalCropAmount = 0
          let totalCashTk = 0
          let totalTransportCost = 0
          let totalOtherCost = 0
          let totalAmount = 0
          const materialTotalArr = []
          if (this.form.circular_type !== 4) {
            this.materials.forEach(materialItem => {
              materialTotalArr.push({
                cir_mat_id: materialItem.id,
                quantity: 0,
                amount: 0
              })
            })
          }
          this.form.details.forEach((item, index) => {
            totalFarmer += item.total_farmer ? parseInt(item.total_farmer) : 0
            totalLandQty += item.land_quantity ? parseFloat(item.land_quantity) : 0
            totalCropQty += item.crop_quantity ? parseFloat(item.crop_quantity) : 0
            totalCropAmount += item.crop_amount ? parseFloat(item.crop_amount) : 0
            totalCashTk += item.cash_tk ? parseFloat(item.cash_tk) : 0
            totalTransportCost += item.transport_cost ? parseFloat(item.transport_cost) : 0
            totalOtherCost += item.other_cost ? parseFloat(item.other_cost) : 0
            totalAmount += item.total_amount ? parseFloat(item.total_amount) : 0
            if (this.form.circular_type !== 4) {
              item.materials.forEach((materialItem, materialIndex) => {
                materialTotalArr[materialIndex].quantity += materialItem.quantity ? parseFloat(materialItem.quantity) : 0
                materialTotalArr[materialIndex].amount += materialItem.amount ? parseFloat(materialItem.amount) : 0
              })
            }
          })
          this.totalItem.total_farmer = totalFarmer
          this.totalItem.land_quantity = totalLandQty
          this.totalItem.crop_quantity = totalCropQty
          this.totalItem.crop_amount = totalCropAmount
          this.totalItem.cash_tk = totalCashTk
          this.totalItem.transport_cost = totalTransportCost
          this.totalItem.other_cost = totalOtherCost
          this.totalItem.total_amount = totalAmount
          if (this.form.circular_type !== 4) {
            materialTotalArr.forEach((materialItem, materialIndex) => {
              this.totalItem.materials[materialIndex].quantity = materialItem.quantity
              this.totalItem.materials[materialIndex].amount = materialItem.amount
            })
          }
        },
        calculateAssociateInfo (index) {
            // check land quantity
            let totalLandQty = 0
            this.form.details.forEach(detailItem => {
            totalLandQty += detailItem.land_quantity ? parseFloat(detailItem.land_quantity) : 0
            })
            if (totalLandQty > this.districtAllocation.land_quantity) {
            this.$toast.error({
                title: 'Error',
                message: 'Total land quantity of upazila can not be larger than total land quantity of district',
                color: '#D6E09B'
            })
            this.form.details[index].land_quantity = this.districtAllocation.land_quantity - (totalLandQty - this.form.details[index].land_quantity)
            this.processCalculateAssociateInfo(index)
            return false
            }
            // check transport cost
            let totalTransportCost = 0
            this.form.details.forEach(detailItem => {
                totalTransportCost += detailItem.transport_cost ? parseFloat(detailItem.transport_cost) : 0
            })
            if (totalTransportCost > this.districtAllocation.transport_cost) {
            this.$toast.error({
                title: 'Error',
                message: 'Total land quantity of upazila can not be larger than total land quantity of district',
                color: '#D6E09B'
            })
            this.form.details[index].transport_cost = this.districtAllocation.transport_cost - (totalTransportCost - this.form.details[index].transport_cost)
            this.processCalculateAssociateInfo(index)
            return false
            }
            // check continent cost
            let totalContinentCost = 0
            this.form.details.forEach(detailItem => {
                totalContinentCost += detailItem.other_cost ? parseFloat(detailItem.other_cost) : 0
            })
            if (totalContinentCost > this.districtAllocation.other_cost) {
                this.$toast.error({
                    title: 'Error',
                    message: 'Total land quantity of upazila can not be larger than total land quantity of district',
                    color: '#D6E09B'
                })
                this.form.details[index].other_cost = this.districtAllocation.other_cost - (totalContinentCost - this.form.details[index].other_cost)
                this.processCalculateAssociateInfo(index)
                return false
            }
            this.processCalculateAssociateInfo(index)
        },
        processCalculateAssociateInfo (index) {
            const crop = this.$store.state.incentiveGrant.commonObj.cropList.find(crp => crp.value === parseInt(this.form.crop_id))
            const landQty = this.form.details[index].land_quantity ? parseFloat(this.form.details[index].land_quantity) : 0
            const cropQty = parseFloat(this.distributionPolicyCropInfo.qnty) * landQty
            this.form.details[index].crop_quantity = cropQty
            this.form.details[index].crop_amount = cropQty * crop.price
            let totalMaterialQty = 0
            let totalMaterialAmount = 0
            this.materials.forEach((materialItem, materialIndex) => {
                const material = this.distributionPolicyMatInfo.find(item => item.material_id === materialItem.material_id)
                const distributionPolicyMatQty = typeof material !== 'undefined' ? material.quantity : 0
                const actualMaterial = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(obj => obj.value === parseInt(materialItem.material_id))
                const materialQty = distributionPolicyMatQty * landQty
                const materialAmount = materialQty * actualMaterial.price
                this.form.details[index].materials[materialIndex].quantity = materialQty
                this.form.details[index].materials[materialIndex].amount = materialAmount
                totalMaterialQty += materialQty
                totalMaterialAmount += materialAmount
            })
            const transportCost = this.form.details[index].transport_cost ? parseFloat(this.form.details[index].transport_cost) : 0
            const continentCost = this.form.details[index].other_cost ? parseFloat(this.form.details[index].other_cost) : 0
            this.form.details[index].material_quantity = totalMaterialQty
            this.form.details[index].material_amount = totalMaterialAmount
            this.form.details[index].total_amount = this.form.details[index].crop_amount + totalMaterialAmount + transportCost + continentCost
          this.getTotalRowInfo()
        }
    }
}
</script>
<style lang="scss">
    .hidden_header {
        display: none;
    }
</style>
